import { useSitesPosts } from 'stores/posts.store'
import { user, conf } from 'stores'

const createHeaders = () => ({ 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` })

/** Création d'un nouveau post. */
const createPost = async (options = {}) => {
  const { siteId, data } = options
  if (!siteId || !data) return

  const url = `${conf.API_URL}/experiences-service/posts/site/${siteId}`
  return fetch(url, { method: 'POST', headers: createHeaders(), body: JSON.stringify(data) })
    .then(res => res.json())
    .then(({ post, error }) => {
      if (error) throw error
      if (post?._id) {
        useSitesPosts.setState(state => ({ [siteId]: [...(state[siteId] || []), post] }))
        return post
      }
    })
}

const readSitePosts = async (options = {}) => {
  const { siteId } = options
  if (!siteId) return

  useSitesPosts.setState(state => ({ ...state, [siteId]: null }))
  const url = `${conf.API_URL}/experiences-service/posts/site/${siteId}`
  return fetch(url, { method: 'GET', headers: createHeaders() })
    .then(res => res.json())
    .then(({ posts }) => {
      if (Array.isArray(posts)) {
        useSitesPosts.setState(state => ({ ...state, [siteId]: posts }))
        return posts
      }
      return []
    })
}

const updatePost = async (options = {}) => {
  const { siteId, data } = options
  if (!siteId || !data?._id) return

  const url = `${conf.API_URL}/experiences-service/posts/site/${siteId}/${data?._id}`
  return fetch(url, { method: 'PUT', headers: createHeaders(), body: JSON.stringify(data) })
    .then(res => res.json())
    .then(({ post }) => {
      useSitesPosts.setState(state => {
        if (!state[siteId]) state[siteId] = []
        const index = state[siteId]?.findIndex(p => p._id === post._id)

        index >= 0 ? (state[siteId][index] = post) : state[siteId].push(post)
        return { [siteId]: [...state[siteId]] }
      })
      return post
    })
}

export const deletePost = async options => {
  const { siteId, postId } = options
  if (!siteId || !postId) return

  const url = `${conf.API_URL}/experiences-service/posts/site/${siteId}/${postId}`
  const res = await fetch(url, { method: 'DELETE', headers: createHeaders() })
  if (res.status === 200) {
    useSitesPosts.setState(state => ({ [siteId]: (state[siteId] || []).filter(post => post._id !== postId) }))
    return true
  } else return false
}

export const postsApi = {
  createPost,
  readSitePosts,
  updatePost,
  deletePost
}
