import React, { useState } from 'react'

export const TextareaWithCounter = ({ uiSchema, formData, onChange, schema, ...props }) => {
  const [charCount, setCharCount] = useState(formData ? formData.length : 0)

  const handleChange = event => {
    const newValue = event.target.value
    setCharCount(newValue.length)
    onChange(newValue)
  }

  return (
    <>
      {schema.title && (
        <label className="control-label" htmlFor={props.idSchema?.$id}>
          {schema.title}
        </label>
      )}
      <textarea
        id={props.idSchema?.$id}
        className="form-control"
        value={formData || ''}
        rows={uiSchema['ui:options']?.rows || 4}
        onChange={handleChange}
        placeholder={schema.title || ''}
      />
      {schema.maxLength && (
        <small style={{ color: charCount > schema.maxLength && 'red' }}>
          {charCount}/{schema.maxLength} caractères
        </small>
      )}
    </>
  )
}
