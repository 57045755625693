import { create } from 'zustand'

export const useSitesPosts = create(() => ({}))

export const useSitePosts = siteId => useSitesPosts(state => state[siteId] || null)

export const useSitePost = (siteId, postId) => {
  const posts = useSitePosts(siteId)
  return posts?.find?.(post => post._id === postId) || null
}
