import React, { useEffect, useState } from 'react'
import JsonSchemaForm from 'react-jsonschema-form'

import Loading from 'components/loading'
import { NavInSite } from 'components/nav'
import { postsApi } from 'lib/api/posts'
import Page from 'pages/page'
import { useSitePost } from 'stores/posts.store'
import { Link } from 'react-router-dom'

export const Post = props => {
  const { siteUid: siteId, postId } = props.match.params || {}

  const post = useSitePost(siteId, postId)
  const [editable, setEditable] = useState({})
  const [deleting, setDeleting] = useState(false)
  const [hasBeenDeleted, setHasBeenDeleted] = useState(false)

  useEffect(() => {
    if (!deleting) {
      setEditable(postId ? (post ? toEditable(post) : null) : {})
      if (postId && !post)
        postsApi.readSitePosts({ siteId }).then(posts => {
          if (!Array.isArray(posts) || !posts.find(post => post._id === postId)) backToSitePosts()
        })
    }
    // eslint-disable-next-line
  }, [post, deleting])

  // eslint-disable-next-line
  useEffect(() => void (hasBeenDeleted && backToSitePosts()), [hasBeenDeleted])

  const onSubmit = ({ formData }) => {
    if (postId) postsApi.updatePost({ siteId, data: toPost(formData) })
    else {
      postsApi
        .createPost({ siteId, data: toPost(formData) })
        .then(post => {
          post?._id && props.history.push({ pathname: `/site/${siteId}/post/${post._id}` })
        })
        .catch(error => console.log('ERROR!!!', error))
    }
  }

  const onDelete = () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
      setDeleting(true)
      postsApi
        .deletePost({ siteId, postId })
        .then(deleted => setHasBeenDeleted(deleted))
        .finally(() => setDeleting(false))
    }
  }

  const backToSitePosts = () => props.history.push({ pathname: `/site/${siteId}/posts` })

  return (
    <Page>
      <NavInSite />
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 0 }}>
        {editable ? (
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
              <Link to={`/site/${siteId}/posts`} className="btn btn-info">
                <i className="fa fa-arrow-left" aria-hidden="true" /> Retour au site
              </Link>
              <div style={{ flex: 1 }} />
              {postId && (
                <button onClick={() => onDelete()} className="btn btn-danger">
                  <i className="fa fa-trash" /> Supprimer
                </button>
              )}
            </div>
            <h2 style={{ margin: 10 }}>{editable._id ? `Article "${editable.title}"` : 'Nouvel article'}</h2>
            <div style={{ maxWidth: '96%', backgroundClip: 'blue', margin: '0 auto' }}>
              <JsonSchemaForm schema={schema} formData={editable} uiSchema={uiSchema} onSubmit={onSubmit}>
                <button className="btn btn-info" type="submit">
                  Enregistrer
                </button>
              </JsonSchemaForm>
            </div>
            <div style={{}} />
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </Page>
  )
}

const toEditable = post => ({
  ...(post || {}),
  notBefore: post?.notBefore ? new Date(post.notBefore).toISOString().split('T')[0] : '',
  notAfter: post?.notAfter ? new Date(post.notAfter).toISOString().split('T')[0] : ''
})

const toPost = editable => ({
  ...editable,
  notBefore: editable?.notBefore ? new Date(editable.notBefore).getTime() : undefined,
  notAfter: editable?.notAfter ? new Date(editable.notAfter).getTime() : undefined
})

const schema = {
  type: 'object',
  required: ['type', 'title', 'text'],
  properties: {
    type: {
      type: 'string',
      title: "Type d'article",
      enum: ['event', 'news'],
      enumNames: ['Evénement', 'Nouveauté'],
      default: 'event'
    },
    title: { type: 'string', title: "Titre de l'article" },
    text: { type: 'string', title: "Contenu de l'article" },
    buttons: {
      type: 'array',
      title: 'Boutons',
      items: {
        type: 'object',
        required: ['label', 'type', 'value'],
        properties: {
          label: { type: 'string', title: 'Texte' },
          type: { type: 'string', title: 'Type de bouton', enum: ['url'], enumNames: ['URL'] },
          value: { type: 'string', title: 'Valeur' }
        }
      }
    },
    published: { type: 'boolean', title: 'Publié' },
    notBefore: { type: 'string', title: "L'article publié n'est pas actif avant cette date", format: 'date' },
    notAfter: { type: 'string', title: "L'article publié n'est plus actif après cette date", format: 'date' }
  }
}

const uiSchema = { type: { classNames: 'inline' } }
