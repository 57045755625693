import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import * as api from "../../lib/api";
import { t } from "../../stores/i18n.store";

export default class NavInSite extends Component {
  state = {
    loading: false,
    experiences: [],
    error: "",
    isExpert: false,
    leaderboards: [],
  };

  componentDidMount() {
    const siteUid = this.props.match.params.siteUid;
    if (!siteUid) {
      return;
    }
    this.setState({ loading: true });
    api.experiences.getFromSite(siteUid).then((experiences) => {
      this.setState({ experiences, loading: false });
    });
    api.users.me().then(me => {
      const isExpert = me.expert || !!me.scopes?.find(s => s === 'admin')
      const isAdmin = !!me.scopes.find(s => s === 'admin')
      const isOwner =
        me?.scopes?.includes('admin') ||
        me?.scopes.some(scope => {
          const parts = scope?.split('/')
          return parts?.[0] === 'site' && parts[1] === siteUid && ['owner'].includes(parts[2])
        }) ||
        false
      this.setState({ isExpert, isAdmin, isOwner })
    })
    api.leaderboards
      .getFromSite(siteUid)
      .then((leaderboards) => this.setState({ leaderboards }));
  }

  render() {
    const { loading, experiences, isAdmin, isOwner, leaderboards } = this.state;
    const { siteUid } = this.props.match.params;
    const experiencesIds = experiences.map(({ _id }) => _id);
    if (!siteUid) {
      return null;
    }
    return (
      <nav>
        <div className="nav-block">
          <h3>{t("nav-experiences")}</h3>
          <ul>
            {loading && <li key="loading">{t("nav-loading-experiences")}</li>}
            {experiences &&
              experiences
                .sort((e1, e2) => e1.name + e1.locale - (e2.name + e2.locale))
                .map((xp) => {
                  return (
                    <li key={xp._id}>
                      <NavLink
                        to={"/site/" + siteUid + "/experience/" + xp._id}
                      >
                        {xp.name + "-" + xp.locale}
                      </NavLink>
                    </li>
                  );
                })}
            {isAdmin && (
              <li key="add">
                <NavLink to={"/site/" + siteUid + "/experience/"} exact>
                  <i className="fa fa-plus" />
                  {t("nav-add-experience")}
                </NavLink>
              </li>
            )}
          </ul>
        </div>
        <div className="nav-block">
          <h3>
            <a
              href={
                "https://scores.explor.games/leaderboard/experiences/" +
                experiencesIds.join(",")
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("nav-scores")}
            </a>
          </h3>
        </div>

        <div className="nav-block">
          <h3>
            <NavLink to={"/site/" + siteUid + "/statistics/"}>
              {t("nav-stats")}
            </NavLink>
          </h3>
        </div>
        {leaderboards.length > 0 && (
          <div className="nav-block">
            <h3>{t("nav-leaderboard")}</h3>
            <ul>
              {leaderboards &&
                leaderboards
                  .sort((e1, e2) => e1.name - e2.name)
                  .map((l) => {
                    return (
                      <li key={l._id}>
                        <a
                          href={
                            "https://scores.explor.games/leaderboard/" + l._id
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {l.name}
                        </a>
                      </li>
                    );
                  })}
            </ul>
          </div>
        )}
        {isOwner && (
          <div className="nav-block">
            <h3>
              <NavLink to={'/site/' + siteUid + '/posts'}>{t('nav-posts')}</NavLink>
            </h3>
          </div>
        )}
        {isAdmin && (
          <div className="nav-block">
            <h3>{t("nav-migration")}</h3>
            <ul>
              <li>
                <NavLink
                  exact
                  to={"/site/" + siteUid + "/integration/explorrando"}
                >
                  Explorrando
                </NavLink>
              </li>
            </ul>
          </div>
        )}
      </nav>
    );
  }
}
