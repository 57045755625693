import React, { Component } from 'react'
import { Prompt } from 'react-router-dom'
import JsonSchemaForm from 'react-jsonschema-form'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'

import { scenarios as scenariosApi, modules as modulesApi } from '../../lib/api'
import Action from './action'
import BoundingBox from './map/boundingbox'
import BundlePicker from './bundlepicker'
import Color from './color'
import CategoriePicker from './categoriepicker'
import { CategoryPicker } from './categoryPicker'
import { CategoryElementPicker } from './categoryElementPicker'
import CharacterPicker from './characterpicker'
import CoordinatesPicker from './map/coordinates'
import ExperiencePicker from './experiencepicker'
import FixedText from './fixedtext'
import GroundOverlayLocator from './groundoverlaylocator'
import MapPicker from './mappicker'
import Markers from './map/markers'
import ModuleTypePicker from './moduletypepicker'
import ModulePicker from './modulepicker'
import PointCheck from './pointcheck'
import PointPicker from './pointpicker'
import PointTrigger from './pointtrigger'
import { PointTriggerForm } from './pointTriggerForm'
import { PointValidation } from './pointValidation'
import { PopupPicker } from './popupPicker'
import QRCode from './qrcode'
import { ImagePicker, SoundPicker, VideoPicker, ResourcePicker, ApplicationImagePicker } from './resourcepicker'
import { ResizeModePicker } from './resizeModePicker'
import RoutePicker from './routepicker'
import ScenarioPicker from './scenariopicker'
import SitePicker from './sitepicker'
import Scope from './scope'
import Segment from './map/segment'
import { SwitchCondition } from './switchCondition'
import TagPicker from './tagpicker'
import { TextareaWithCounter } from './textareaWithCounter'
import TimerPicker from './timerpicker'
import TrackPicker from './trackpicker'
import TrackTypePicker from './tracktypepicker'
import TrackOrModuleTypePicker from './trackormoduletypepicker'
import Uid from './uid'
import VariablePicker from './variablepicker'
import VoiceInput from './voiceinput'
import { ZoneOnPicture, ZonePicker } from './zoneonpicture'

import * as stores from '../../stores'

class GenericForm extends Component {
  state = {
    schema: null,
    uiSchema: null,
    formData: this.props.initialFormData || {},
    lastType: null,
    loading: true,
    modified: false
  }

  onUnload = event => {
    // the method that will be used for both add and remove event
    if (this.state.modified) {
      event.returnValue =
        'Vous avez des modifications non enregistrées.\nSouhaitez vous quitter cette page' +
        ' et perdre les modifications non enregistrée ? '
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.initialFormData &&
      this.props.initialFormData &&
      nextProps.initialFormData.updatedAt !== this.props.initialFormData.updatedAt
    ) {
      if (this.state.modified) {
        const id = Date.now()
        stores.notifications.set(id, {
          id,
          type: 'danger',
          title: "Quelqu'un d'autre  vient de modifier cette page",
          content: 'Risque de perte de données'
        })
      } else {
        this.setState({ formData: nextProps.initialFormData })
      }
    }
  }

  isNullish(o) {
    if (!o) {
      return true
    }

    if (Array.isArray(o)) {
      return o.length === 0
    }
    if (typeof o === 'object') {
      for (var key in o) {
        if (o.hasOwnProperty(key)) return false
      }
      return true
    }
    return false
  }

  compare(data1, data2) {
    if (!data1) return !data2
    if (typeof data1 === 'object' /* object or array */) {
      return Object.keys(data1).reduce((acc, key) => {
        if (!acc) return false
        return this.compare(data1[key], data2 && data2[key])
      }, true)
    }
    return data1 === data2
  }

  change = form => {
    if (!this.compare(form.formData, this.props.initialFormData)) {
    }
    this.setState({
      formData: form.formData,
      modified: !this.compare(form.formData, this.props.initialFormData)
    })
    this.props.onChange && this.props.onChange(form.formData)
    this.setState({ saveError: null })
  }

  validate = (formData, errors) => {
    function recursiveContainTranfert(o) {
      if (!o) {
        return
      }
      if (Array.isArray(o) || typeof o === 'object') {
        let contains = false
        for (var key in o) {
          contains = contains || recursiveContainTranfert(o[key])
        }
        return contains
      }
      if (typeof o !== 'string') {
        return false
      }
      return o.indexOf('__transfert__') === 0
    }

    for (var key in formData) {
      if (recursiveContainTranfert(formData[key])) {
        errors[key].addError("Merci d'attendre la fin du transfert")
      }
    }
    return errors
  }

  save = async form => {
    if (this.state.saving) return
    this.setState({ modified: false, saving: true }, async () => {
      if (this.props.onSubmit) {
        await findAndModifyObject(
          form.formData, // Objet à évaluer.
          {
            experienceId: form.formData.experienceId, // ID de l'expérience.
            baseName: form.formData.internalName || form.formData.name || form.formData.title || form.formData.type // Nom de l'élément.
          }
        )
          .then(() => this.props.onSubmit(form.formData))
          .then(() => {
            const id = Date.now()
            stores.notifications.set(id, { id, type: 'success', title: 'Enregistrement réussi', autoClose: 1500 })
            this.setState({ saving: false, saveError: null })
          })
          .catch(e => {
            const id = Date.now()
            console.error(e)
            stores.notifications.set(id, {
              id,
              type: 'danger',
              title: 'Enregistrement impossible',
              content: e.toString() || 'Erreur'
            })
            this.setState({ saving: false, saveError: e })
          })
      } else this.setState({ saving: false, saveError: null })
    })
  }

  recursiveToJS(mobx) {
    const o = toJS(mobx)
    if (!o) {
      return o
    }
    let res
    if (Array.isArray(o)) {
      res = []
      for (var i = 0; i < o.length; i++) {
        res[i] = this.recursiveToJS(o[i])
      }
      return res
    }
    if (typeof o === 'object') {
      res = {}
      for (var key in o) {
        if (o.hasOwnProperty(key)) {
          res[key] = this.recursiveToJS(o[key])
        }
      }
      return res
    }
    return o
  }

  render() {
    const { formPath, formContext, experience, advanced, onSubmit } = this.props
    let { saving, saveError, formData } = this.state

    let form = stores.forms.get(advanced ? formPath + '.advanced' : formPath, experience)
    if (form.loading) {
      return <div>Chargement en cours</div>
    }
    if (!form.schema) {
      return <div>Pas de schema défini, passez par l'édition en JSON brut</div>
    }

    if (form.schema.properties.instruction) {
      if (!formData.createdAt) {
        formData.instruction = stores.instructions[formData.type]
      }
    }
    //form.schema.properties.instruction.default = stores.instructions[formData.type]

    form = toJS(form)
    formData = this.recursiveToJS(formData)

    return (
      <JsonSchemaForm
        schema={form.schema}
        uiSchema={form.uiSchema}
        fields={{
          action: Action,
          applicationimagepicker: ApplicationImagePicker,
          bundle: BundlePicker,
          categoriepicker: CategoriePicker,
          categorypicker: CategoryPicker,
          categoryelementpicker: CategoryElementPicker,
          characterpicker: CharacterPicker,
          colorpicker: Color,
          coordinates: CoordinatesPicker,
          experience: ExperiencePicker,
          fixed: FixedText,
          imagepicker: ImagePicker,
          boundingbox: BoundingBox,
          groundoverlaylocator: GroundOverlayLocator,
          mappicker: MapPicker,
          markers: Markers,
          modulepicker: ModulePicker,
          moduletypepicker: ModuleTypePicker,
          pointTriggerForm: PointTriggerForm,
          pointcheck: PointCheck,
          pointpicker: PointPicker,
          pointtrigger: PointTrigger,
          pointValidation: PointValidation,
          popupPicker: PopupPicker,
          qrcode: QRCode,
          resourcepicker: ResourcePicker,
          resizeModePicker: ResizeModePicker,
          routepicker: RoutePicker,
          scope: Scope,
          segment: Segment,
          scenariopicker: ScenarioPicker,
          soundpicker: SoundPicker,
          sitepicker: SitePicker,
          switchcondition: SwitchCondition,
          tagpicker: TagPicker,
          textareaWithCounter: TextareaWithCounter,
          trackpicker: TrackPicker,
          timerpicker: TimerPicker,
          tracktypepicker: TrackTypePicker,
          trackormoduletypepicker: TrackOrModuleTypePicker,
          uid: Uid,
          variablepicker: VariablePicker,
          zonepicker: ZonePicker,
          zoneonpicture: ZoneOnPicture,
          videopicker: VideoPicker,
          voiceinput: VoiceInput
        }}
        formData={formData}
        formContext={{
          formData: formData,
          schema: form.schema,
          uiSchema: form.uiSchema,
          experienceUid: this.props.experienceUid,
          scenarioUid: this.props.scenarioUid,
          ...formContext,
          scenarios: [...(formContext?.scenarios || [])]
        }}
        onChange={this.change}
        onSubmit={this.save}
        validate={this.validate}
      >
        {onSubmit && (
          <div>
            {saveError && (
              <p className="bg-danger" style={{ padding: '1em' }}>
                Impossible de sauvegarder : <b>{saveError.message}</b>
              </p>
            )}
            <button type="submit" className={'btn btn-block ' + (saveError ? 'btn-danger' : 'btn-primary')} disabled={saving}>
              {saving && <i className="fa fa-gear fa-spin" />}
              {this?.props?.submitButtonText || (formData._id ? 'Enregistrer les modifications' : 'Créer')}
            </button>
            {saving}
          </div>
        )}
        <Prompt
          when={this.state.modified}
          message={location =>
            'Vous avez des modifications non enregistrées.\nSouhaitez vous quitter cette page et perdre les modifications non enregistrée ? '
          }
        />
      </JsonSchemaForm>
    )
  }
}

/** Transformation pour créer un nouveau scénario */
const transformScenario = async (obj, options) => {
  const opt = { ...{ baseName: 'scenario' }, ...(options || {}) }
  if (obj === '*new-scenario*' && opt.experienceId) {
    return await scenariosApi
      .create({
        experienceId: opt.experienceId,
        name: opt.baseName + ' - ' + (opt.createClueModule ? 'indice' : 'étapes de jeu')
      })
      .then(async scenario => {
        if (scenario?._id && opt.createClueModule) {
          await modulesApi.create({ experienceId: opt.experienceId, scenarioId: scenario._id, type: 'gallery', canGoBack: true })
        }

        return scenario?._id
      }) // Retourne l'ID du scénario créé.
      .catch(() => undefined) // En cas d'erreur, retourne "undefined".
  } else return obj
}

/** Transformation pour les actions. */
const transformActions = async (obj, options) => {
  // Si l'objet est un tableau, la fonction est exécuté sur chaque élément.
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) obj[i] = await transformActions(obj[i], options)
  }
  // Sinon, début du traitement pour les actions.
  else {
    // Si l'action a le type "load_scenario" et la valeur "*new-scenario*" -> Création d'un scénario vide et assignation de son ID.
    if (obj?.type === 'load_scenario' && obj.scenario === '*new-scenario*' && options.experienceId) {
      obj.scenario = await transformScenario(obj.scenario, options)
    }
  }
  return obj
}

/** Transformation pour les points. */
const transformPoint = async obj => {
  if (['code', 'qrcode', 'location', 'image', 'none'].includes(obj.type)) {
    const save = { ...obj }
    for (const key of ['code', 'distance', 'target', 'inputType']) delete obj[key] // Suppression de toutes les propriétés liés aux types de validation.
    // Seules les propriétés nécessaires au type sont conservées.
    if (obj.type === 'code' || obj.type === 'qrcode') obj.code = save.code
    if (obj.type === 'code') obj.inputType = save.inputType || 'text'
    else if (obj.type === 'location') obj.distance = save.distance
    else if (obj.type === 'image') obj.target = save.target
  }

  return obj
}

/** Liste des transformations à appliquer sur les objets à l'enregistrement. */
const transformations = [
  { keys: ['action', 'actions', 'defaultActions', 'firstActions'], fct: transformActions },
  { keys: ['scenario'], fct: transformScenario },
  { keys: ['clue'], fct: (obj, options) => transformScenario(obj, { ...options, createClueModule: true }) },
  { keys: ['check'], fct: transformPoint }
]

/** Fonction asynchrone et récursive, permettant de parcourir un objet, et effectuer certaines modifications. */
const findAndModifyObject = async (obj, options) => {
  // Pour chaque propriété de l'objet...
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue // Passe à la suite si ce n'est pas une propriété directe de l'objet.

    // Pour chaque transformation possible...
    for (const transformation of transformations) {
      // ... si la clé correspond, la fonction de transformation est exécutée.
      if (transformation.keys.includes(key)) obj[key] = await transformation.fct(obj[key], options)
    }
    // La fonction continue sur les propriétés imbriquées de manière récursive.
    if (typeof obj[key] === 'object' && obj[key] !== null) await findAndModifyObject(obj[key], options)
  }
}

export default observer(GenericForm)
