import React, { useEffect, useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import Loading from '../../components/loading'
import { Page } from '../'
import { NavInExperience } from '../../components/nav'
import { t } from 'stores/i18n.store'
import { conf } from 'stores'

export default props => {
  const expId = useRef(props.match.params.experienceUid).current

  const now = useRef(new Date()).current
  const [from, setFrom] = useState(new Date(new Date().setMonth(now.getMonth() - 1)))
  const [to, setTo] = useState(new Date(now))
  const [ratings, setRatings] = useState(null)
  const [nbSessions, setNbSessions] = useState(null)

  // eslint-disable-next-line
  useEffect(() => void fetchData(), [])

  const fetchData = () => {
    setRatings(null)
    fetch(`${conf.API_URL}/sessions-service/experiences/${expId}/ratings?from=${formatDate(from)}&to=${formatDate(to)}`)
      .then(res => res.json())
      .then(events => {
        const sessionIds = new Set()
        const ratings = (events || []).reduce((acc, event) => {
          sessionIds.add(event.sessionId)
          const { key, score } = event
          if (!acc[key]) acc[key] = { count: 0, sum: 0, data: [0, 0, 0, 0, 0] }
          acc[key].count++
          acc[key].sum += score || 0
          acc[key].data[score - 1]++
          return acc
        }, {})
        setNbSessions(sessionIds.size) // Nombre de sessions trouvées.

        return ratings
      })
      .then(r => {
        setRatings(
          Object.entries(r).map(([key, value]) => ({ key, ...value, mean: Math.round((value.sum / value.count) * 10) / 10 }))
        )
      })
      .catch(() => {})
  }

  return (
    <Page>
      <NavInExperience />
      <div id="content" style={{ overflow: 'scroll' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', marginBottom: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <label htmlFor="from" style={{ marginRight: 15 }}>
              {t('page-stats-date-from')}
            </label>
            <input
              id="from"
              className="form-control"
              type="date"
              style={{ marginRight: 30 }}
              value={formatDate(from)}
              onChange={event => setFrom(new Date(event.target.value))}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <label htmlFor="to" style={{ marginRight: 15 }}>
              {t('page-stats-date-to')}
            </label>
            <input
              id="to"
              className="form-control"
              type="date"
              style={{ marginRight: 30 }}
              value={formatDate(to)}
              onChange={event => setTo(new Date(event.target.value))}
            />
          </div>
          <button onClick={() => fetchData()}>Actualiser</button>
        </div>

        {ratings === null ? (
          <Loading />
        ) : ratings?.length === 0 ? (
          <div>Rien à afficher</div>
        ) : (
          <div>
            <p>{nbSessions} session{nbSessions > 1 ? 's' : ''}</p>
            {ratings.map((rating, index) => (
              <div key={index}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <p style={{ fontSize: 20, fontWeight: 'bold' }}>{capitalize(rating.key)}</p>
                  <p style={{ marginLeft: 5, marginRight: 5 }}>-</p>
                  <p>{rating.mean?.toLocaleString()} / 5</p>
                </div>
                <div style={{ maxWidth: 800 }}>
                  <Bar
                    data={{
                      labels: ['1', '2', '3', '4', '5'],
                      datasets: [
                        {
                          label: `Nombre de notes "${rating.key}"`,
                          backgroundColor: 'rgba(75,192,192,0.9)',
                          data: rating.data
                        }
                      ]
                    }}
                    options={{}}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Page>
  )
}

const formatDate = date => {
  if (!date) return ''
  var d = new Date(date)
  var month = '' + (d.getMonth() + 1)
  var day = '' + d.getDate()
  var year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)
