import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import Loading from '../loading'

import * as api from '../../lib/api'
import { apiCache } from '../../stores'
import { moduleLabel } from '../../stores'
import { PasteModule } from './pasteModule'
import { t } from 'stores/i18n.store'

class NavInScenarioStatic extends Component {
  state = { modules: [], loading: true, clipboardModule: localStorage.getItem('clipboard-module') || undefined }

  componentDidMount() {
    const { scenarioUid } = this.props.match.params
    if (scenarioUid) {
      api.modules.getFromScenario(scenarioUid).then(modules => {
        this.setState({ modules, loading: false })
      })
    } else {
      this.setState({ loading: false })
    }
  }

  render() {
    const { siteUid, scenarioUid, trackUid, pointUid, experienceUid } = this.props.match.params
    const { history } = this.props

    const { loading } = this.state

    if (loading) return <Loading />

    let modules = apiCache.modules.getFrom('scenarios', scenarioUid)
    if (modules) {
      modules = modules
        .sort((a, b) => (a.order || 0) - (b.order || 0))
        .map(module => {
          const title = module.internalName || module.title || ''
          return (
            <li key={module._id}>
              <NavLink
                to={
                  '/site/' +
                  siteUid +
                  '/experience/' +
                  experienceUid +
                  (trackUid ? '/track/' + trackUid : '') +
                  (pointUid ? '/point/' + pointUid : '') +
                  '/scenario/' +
                  scenarioUid +
                  '/module/' +
                  module._id
                }
                key={module._id}
              >
                {moduleLabel[module.type] || module.type} {title && ' - ' + title}
              </NavLink>
            </li>
          )
        })
    }
    return (
      <nav>
        <div className="nav-block">
          <h3>Scénario</h3>
          <ul>
            <li>
              <NavLink exact to={'/site/' + siteUid + '/experience/' + experienceUid + '/scenario/' + scenarioUid}>
                Détail
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="nav-block">
          <h3>Modules</h3>
          <ul>
            {modules}

            <li key="add">
              <NavLink
                exact
                to={
                  '/site/' +
                  siteUid +
                  '/experience/' +
                  experienceUid +
                  (trackUid ? '/track/' + trackUid : '') +
                  (pointUid ? '/point/' + pointUid : '') +
                  '/scenario/' +
                  scenarioUid +
                  '/module/'
                }
              >
                <i className="fa fa-plus" /> {t('btn-add-module')}
              </NavLink>
            </li>
            <PasteModule scenarioId={scenarioUid} history={history} refresh={() => this.setState({ refresh: Math.random() })} />
          </ul>
        </div>
      </nav>
    )
  }
}

export default withRouter(NavInScenarioStatic)
