import React, { Component } from 'react'
import QRCodeReact from 'qrcode.react'
import { Link } from 'react-router-dom'

import BookResource from './resource'
import BookText from './text'
import BookAction from './action'
import Diagnostic from './diagnostic'
import checks from '../../../lib/check'
import checkPath from './lib/checkPath'
import { moduleLabel } from '../../../stores'

const AugmentedReality = ({ module, highlights }) => {
  const { targets } = module
  return (
    <div>
      {(targets || []).map(({ name, type, autoplay, resource, source }, index) => {
        const { resource: sourceFile, width } = source || {}
        return (
          <div key={name}>
            <h3>
              {name} ({type})
            </h3>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
              <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <h4>Source</h4>
                <BookResource
                  id={sourceFile}
                  title={`Largeur ${width} mètres`}
                  path={'targets°|°' + index + '°|°source°|°resource'}
                  highlights={highlights}
                  style={{ maxWidth: '200px', maxHeight: '200px', margin: '10px' }}
                />
              </div>
              <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <h4>Remplacé par</h4>
                <BookResource
                  id={resource}
                  path={'targets°|°' + index + '°|°resource'}
                  highlights={highlights}
                  style={{ maxWidth: '200px', maxHeight: '200px', margin: '10px' }}
                />
                {autoplay && (
                  <span>
                    <i className="fa fa-play" /> Lecture automatique de la vidéo
                  </span>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const Choice = ({ module, chapters, highlights }) => {
  const { choices, defaultChoice } = module

  return (
    <div>
      {(choices || []).map(({ _id, label, picture, actions }, index) => {
        const actionsClassName = highlights && checkPath(highlights, 'choices°|°' + index + 'actions') ? 'highlighted' : ''
        return (
          <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
            <BookText
              collection="modules"
              id={module._id}
              path={'choices°|°' + index + '°|°label'}
              text={label}
              highlights={highlights}
            />
            {defaultChoice === _id ? ' (valeur par défaut)' : ''}
            <BookResource id={picture} path={'choices°|°' + index + '°|°picture'} highlights={highlights} />
            <div className={actionsClassName}>
              {(actions || []).map((action, index) => (
                <BookAction action={action} chapters={chapters} key={index} />
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}
const ConversationTextVoice = ({ collection, path, id, sound, characterName, text, highlights }) => {
  return (
    <div className="dialog-text">
      {characterName && <small>{characterName + ' : '}</small>}
      <BookResource id={sound} path={path + 'sound'} highlights={highlights} />
      <BookText
        collection={collection}
        id={id}
        path={path + 'text'}
        text={text}
        style={{ marginTop: 0 }}
        highlights={highlights}
      />
    </div>
  )
}

const ConversationImages = ({ background, icon, animate, position, path, highlights }) => {
  if (!icon) {
    return null
  }
  const fromLeft = position === 'bottom|start'
  return (
    <div className="dialog-images">
      {(background || module.background) && (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <BookResource
            id={background || module.background}
            highlights={background ? highlights : null}
            path={background ? path + 'background' : null}
          />
          <BookResource
            id={icon}
            style={{
              position: 'absolute',
              right: fromLeft ? 'auto' : 0,
              left: fromLeft ? 0 : 'auto',
              bottom: 0,
              top: 0
            }}
            noBackgroundMesh
            highlights={highlights}
            path={path + 'icon'}
          />
        </div>
      )}
      {!background && !module.background && <BookResource id={icon} highlights={highlights} path={path + 'icon'} />}

      {animate && (
        <i
          className={
            'fa fa-2x ' +
            (fromLeft ? ' fa-angle-double-right dialog-animate-icon-right' : ' fa-angle-double-left dialog-animate-icon-left')
          }
        />
      )}
    </div>
  )
}

const Conversation = ({ module, chapters, highlights }) => {
  const experience = chapters[module.experienceId].experience || {}
  const characters = experience.characters || []

  return (module.dialogs || []).map(
    ({ icon, sound, text, position, animate, character: characterAndVariantId, background }, index) => {
      const [characterId, variantId] = (characterAndVariantId || '').split('°|°')
      let { label: characterName, variants } = characters.find(({ _id }) => characterId === _id) || {}
      if (variantId && variants) {
        const variant = variants.find(({ _id }) => variantId === _id)
        characterName = characterName + (variant && variant.label)
      }
      return (
        <div className="dialog " key={index}>
          {position === 'bottom|start' && (
            <ConversationImages
              background={background || module.background}
              icon={icon}
              animate={animate}
              position={position}
              highlights={highlights}
            />
          )}
          <ConversationTextVoice
            collection="modules"
            id={module._id}
            path={'dialogs°|°' + index + '°|°'}
            text={text}
            sound={sound}
            characterName={characterName}
            highlights={highlights}
          />
          {position !== 'bottom|start' && (
            <ConversationImages
              background={background || module.background}
              icon={icon}
              animate={animate}
              position={position}
              path={'dialogs°|°' + index + '°|°'}
              highlights={highlights}
            />
          )}
        </div>
      )
    }
  )
}
const Compass = () => {
  return <em>Rien à paramétrer sur ce module</em>
}

const Diaporama = ({ module, highlights }) => {
  const { _id, pictures, autoplay, sound, text } = module
  return (
    <div>
      {(pictures || []).map((picture, index) => (
        <BookResource id={picture.file} key={picture.file} path={'pictures°|°' + index + '°|°file'} highlights={highlights} />
      ))}
      {autoplay && (
        <span>
          <br />
          <i className="fa fa-play" /> Passage automatique des images
        </span>
      )}
      <BookResource id={sound} path="sound" highlights={highlights} />
      <BookText text={text} collection="modules" id={_id} path="text" highlights={highlights} />
    </div>
  )
}
const DragDrop = ({ module, highlights }) => {
  const { picture, drops, drags, dropType } = module
  const indexes = {}
  ;(drops || []).forEach(({ _id }, index) => (indexes[_id] = index + 1))

  return (
    <div>
      <span>
        Mécanique de jeu :{dropType === 'normal' && <b>Pièces à côté du puzzle, validation finale en cliquant sur le bouton</b>}
        {dropType === 'validated' && <b>Pièces à côté du puzzle, validation immédiate lors de la dépose sur une zone de drop</b>}
        {dropType === 'distributed' && (
          <b>Pièces distribuées au hasard dans les zone de dépose, validation finale en cliquant sur le bouton</b>
        )}
      </span>
      <div style={{ position: 'relative' }}>
        <BookResource
          id={picture}
          style={{ width: '100%', maxWidth: 'auto' }}
          styleResource={{ width: '100%', maxWidth: 'auto' }}
          path="picture"
          highlights={highlights}
        />
        {(drops || []).map(({ _id, left, right, bottom, top }, index) => {
          return (
            <div
              key={_id}
              style={{
                position: 'absolute',
                top: top * 100 + '%',
                left: left * 100 + '%',
                width: (right - left) * 100 + '%',
                height: (bottom - top) * 100 + '%',
                backgroundColor: 'rgba(255,255,255,0.1)',
                border: '1px dashed white',
                boxShadow: '1px 1px 12px #555',
                color: 'white',
                textShadow: `-1px 0 0 rgba(0, 0, 0, 0.8), 
             1px 0 0 rgba(0, 0, 0, 0.8), 
             0 -1px 0 rgba(0, 0, 0, 0.8), 
             0 1px 0 rgba(0, 0, 0, 0.8)`,

                textAlign: 'center',
                zIndex: 1,
                overflow: 'hidden',
                borderRadius: '6px',
                pointerEvents: 'none'
              }}
            >
              {index + 1}
            </div>
          )
        })}
      </div>
      <h3>Pièces à placer</h3>
      <div>
        {(drags || []).map(({ points, picture, caption, _id, answers, drop }, index) => {
          const indexAnswers = (answers || []).map(_id => indexes[_id])
          return (
            <BookResource
              id={picture}
              title={`${caption || ''} - Zone ${indexAnswers.join(', ')} - ${points} pts ${
                drop && dropType === 'distributed' ? '- zone de départ : ' + indexes[drop] : ''
              }`}
              key={_id}
              path={'drags°|°' + index + '°|°picture'}
              highlights={highlights}
              style={{ maxWidth: '120px', maxHeight: '120px', margin: '4px' }}
            />
          )
        })}
      </div>
    </div>
  )
}

const Differences = ({ module, highlights }) => {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <BookResource id={module.picture} style={{ maxWidth: '100%' }} hd path="picture" highlights={highlights} />
      {(module.differences || []).map(({ _id, top, left }) => {
        return (
          <i
            key={_id}
            className="fa fa-fw fa-crosshairs"
            style={{
              position: 'absolute',
              top: top * 100 + '%',
              left: left * 100 + '%',
              color: 'white',
              marginLeft: '-6px',
              marginTop: '-6px%'
            }}
          />
        )
      })}
    </div>
  )
}
const Gallery = ({ module, highlights }) => {
  return (
    <div className="icons-container">
      {(module.pictures || []).map((picture, index) => (
        <div style={{ flex: '200 0 0', textAlign: 'center', maxHeight: '200px' }} key={picture.file}>
          <BookResource
            id={picture.file}
            title={picture.caption}
            path={'pictures°|°' + index + '°|°file'}
            highlights={highlights}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      ))}
    </div>
  )
}
const ImageMcq = ({ module, highlights }) => {
  const { images, answers } = module
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(' + (module.columns || 2) + ', 1fr)'
      }}
    >
      {(images || []).map((image, index) => {
        const valid = (answers || []).find(answer => answer === image._id)
        return (
          <div key={image._id}>
            <BookResource
              id={image.picture}
              style={{ maxWidth: '100%', margin: '10px' }}
              title={valid ? 'bonne réponse' : ''}
              className={valid && 'valid'}
              path={'images°|°' + index}
              highlights={highlights}
            />
          </div>
        )
      })}
    </div>
  )
}

const Level = ({ module, highlights }) => {
  const { codes, reward, objective, penalty, marble, levelBackground, levelCover } = module
  return (
    <div>
      <h3>Codes</h3>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {(codes || []).map(code => (
          <QRCodeReact value={code || ''} size={128} key={code} style={{ margin: '4px' }} />
        ))}
      </div>
      <h3>Score</h3>
      <ul>
        <li>Récompense par Qrcode : {reward}</li>
        <li>Penalité à chaque fois que la bille touche le bord : {penalty} </li>
        <li>Objectif à atteindre pour avoir tous les points : {objective}</li>
      </ul>
      <BookResource id={marble} path="marble" highlights={highlights} />
      <BookResource id={levelBackground} path="levelBackground" highlights={highlights} />
      <BookResource id={levelCover} path="levelCover" highlights={highlights} />
    </div>
  )
}

const Markdown = ({ module, highlights }) => {
  return <BookText id={module._id} collection="modules" path="code" text={module.code} highlights={highlights} />
}

const MCQ = ({ module, highlights }) => {
  const { propositions, answers } = module
  return (
    <ul>
      {(propositions || []).map((proposition, index) => {
        const valid = (answers || []).find(answer => answer === proposition)
        return (
          <li key={index} style={{ flex: 1, position: 'relative' }} className={valid ? 'bg-success' : ''}>
            <BookText
              collection="modules"
              id={module._id}
              path={'propositions°|°' + index}
              text={proposition}
              style={{ display: 'inline-block' }}
              highlights={highlights}
            />

            {valid && <i className="fa fa-check text-success" />}
          </li>
        )
      })}
    </ul>
  )
}

const RAD2DEG = 180 / Math.PI
function cartesianToPolar(coord) {
  var lon = Math.atan2(coord.x, -coord.z) * RAD2DEG
  var length = Math.sqrt(coord.x * coord.x + coord.z * coord.z)
  var lat = Math.atan2(coord.y, length) * RAD2DEG

  return { lon, lat }
}

const Photosphere = ({ module, highlights }) => {
  const { picture, orientation, differences } = module
  return (
    <div style={{ position: 'relative' }}>
      <BookResource id={picture} style={{ maxWidth: '100%' }} path="picture" highlights={highlights} />
      {(differences || []).map(difference => {
        const { lon, lat } = cartesianToPolar(difference)
        /* magic numbers everywhere, because fuck future me */
        const yaw = (lon + 270) % 360
        const pitch = lat
        const left = yaw / 360.0
        const top = 0.5 - pitch / 180
        return (
          <i
            key={difference._id}
            className="fa fa-crosshairs"
            style={{
              position: 'absolute',
              top: top * 100 + '%',
              left: left * 100 + '%',
              color: 'white'
            }}
          />
        )
      })}
      orientation {orientation}° par rapport au nord
    </div>
  )
}

const Puzzle = ({ module, highlights }) => {
  const { picture, pieces, shuffleRotation } = module
  return (
    <div>
      <h3>Fond du puzzle</h3>
      <BookResource id={picture} />
      <h3>Puzzle reconsituté</h3>
      <div style={{ position: 'relative' }}>
        <BookResource
          id={picture}
          style={{ width: '100%', maxWidth: 'auto' }}
          styleResource={{ width: '100%', maxWidth: 'auto' }}
          hd
          path="picture"
          highlights={highlights}
        />
        {(pieces || []).map(({ top, left, right, bottom, picture }, index) => {
          return (
            <BookResource
              id={picture}
              key={picture}
              style={{
                position: 'absolute',
                top: top * 100 + '%',
                left: left * 100 + '%',
                width: (right - left) * 100 + '%',
                height: (bottom - top) * 100 + '%'
              }}
              path={'pieces°|°' + index + '°|°picture'}
              highlights={highlights}
              styleResource={{ width: '100%', maxWidth: 'auto' }}
            />
          )
        })}
      </div>
      <span className="fa-stack fa-lg">
        <i className="fa fa-rotate-right fa-stack-1x" />
        {!shuffleRotation && <i className="fa fa-ban fa-stack-2x text-danger" />}
      </span>{' '}
      Faire tourner les pièces
    </div>
  )
}
const QRCodeHunt = ({ module }) => {
  const codes = module.codes || []
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {(codes || []).map((code, index) => (
        <QRCodeReact value={code || ''} size={128} key={code} path={'codes°|°' + index} />
      ))}
    </div>
  )
}

const Quizz = ({ module, highlights }) => {
  const answers = module.answers
  if (!answers || answers.length === 0) {
    return <div>pas de bonne réponse</div>
  }
  if (answers.length === 1) {
    return <div>La bonne réponse est {answers[0]}</div>
  }
  return (
    <div>
      Les bonnes réponses sont
      <ul>
        {(module.answers || []).map((answer, index) => (
          <li key={index}>
            <BookText text={answer} collection="modules" id={module._id} path={'answers°|°' + index} highlights={highlights} />
          </li>
        ))}
      </ul>
    </div>
  )
}

const Rating = ({ module, highlights }) => {
  const ratings = module.ratings || []
  if (ratings.length === 0) {
    return <div>pas de critère de note défini</div>
  }
  return (
    <ul>
      {(ratings || []).map(({ key, label }, index) => (
        <li key={key}>
          {label}
          <BookText text={label} collection="modules" id={module._id} path={'ratings°|°' + index} highlights={highlights} />
        </li>
      ))}
    </ul>
  )
}

const RatingStore = ({ module, highlights }) => {
  const ratings = module.ratings || []
  if (ratings.length === 0) {
    return <div>pas de critère de note défini</div>
  }
  return (
    <ul>
      {(ratings || []).map(({ key, label }, index) => (
        <li key={key}>
          {label}
          <BookText text={label} collection="modules" id={module._id} path={'ratings°|°' + index} highlights={highlights} />
        </li>
      ))}
    </ul>
  )
}

const Scratch = ({ module, highlights }) => {
  const foreground = module.foreground

  return <BookResource id={foreground} title="image de surcouche" path="foreground" highlights={highlights} />
}
const Selfie = ({ module, highlights }) => {
  const [landscape, portrait] = module.tags || []
  return (
    <div>
      <BookText text={module.shareText} collection="modules" id={module._id} path="shareText" highlights={highlights} />
      surcouche en mode paysage
      <BookResource
        id={landscape && landscape.file}
        style={{ display: 'inline-block' }}
        path="tags°|°0°|°file"
        highlights={highlights}
      />
      <br />
      surcouche en mode portrait
      <BookResource
        id={portrait && portrait.file}
        style={{ display: 'inline-block' }}
        path="tags°|°1°|°file"
        highlights={highlights}
      />
      <br />
      {!module.allowSharing && (
        <span className="fa-stack fa-lg">
          <i className="fa fa-facebook fa-stack-1x" />
          <i className="fa fa-ban fa-stack-2x text-danger" />
        </span>
      )}
    </div>
  )
}

const Session = ({ module }) => {
  const { askForName, askForEmail } = module
  return (
    <div>
      {!askForName && (
        <span>
          <span className="fa-stack fa-lg">
            <i className="fa fa-users fa-stack-1x" />
            <i className="fa fa-ban fa-stack-2x text-danger" />
          </span>
          Ne pas demander le nom d'équipe
        </span>
      )}
      {askForName && (
        <span>
          <span className="fa-stack fa-lg">
            <i className="fa fa-users fa-stack-1x" />
          </span>
          Demander le nom d'équipe
        </span>
      )}
      <br />
      {!askForEmail && (
        <span>
          <span className="fa-stack fa-lg">
            <i className="fa fa-envelope-o fa-stack-1x" />
            <i className="fa fa-ban fa-stack-2x text-danger" />
          </span>
          Ne pas demander l'adresse email
        </span>
      )}
      {askForEmail && (
        <span>
          <span className="fa-stack fa-lg">
            <i className="fa fa-envelope-o fa-stack-1x" />
          </span>
          Demander l'adresse email
        </span>
      )}
    </div>
  )
}

const ShakeContext = ({ module }) => {
  const { objective } = module
  return 'Objectif à atteindre pour avoir tous les points : ' + objective
}

const Slasher = ({ module, highlights }) => {
  const { entities, objective } = module
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around'
      }}
    >
      Objectif à atteindre pour avoir tous les points : {objective}
      <br />
      {(entities || []).map(({ slashedFrames, normalFrames, sounds, points, rotation, probability }, index) => {
        return (
          <div
            style={{
              flex: '120px 0 0',
              display: 'flex',
              flexDirection: 'column'
            }}
            className={checkPath(module, 'entities°|°' + index) ? 'highlighted' : ''}
            key={index}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {(normalFrames || []).map?.((f, index) => (
                <BookResource style={{ flex: 1 }} id={f} key={index} title="normal" />
              ))}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {(slashedFrames || []).map?.((f, index) => (
                <BookResource id={f} key={index} title="slashed" />
              ))}
            </div>
            {(sounds || []).map?.((f, index) => (
              <BookResource id={f} key={index} />
            ))}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around'
              }}
            >
              <span>
                <i className="fa fa-2x fa-trophy" /> {points} pts
              </span>
              <span className="fa-stack fa-lg">
                <i className="fa fa-rotate-o fa-stack-1x" />
                {!rotation && <i className="fa fa-ban fa-stack-2x text-danger" />}
              </span>
              <span>proba {probability}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const Video = ({ module, highlights }) => {
  const { video, text, autoplay } = module
  return (
    <div>
      <BookResource id={video} path="video" highlights={highlights} />
      <BookText text={text} collection="modules" id={module._id} path="text" highlights={highlights} />
      {!autoplay && (
        <span className="fa-stack fa-lg">
          <i className="fa fa-play fa-stack-1x" />
          <i className="fa fa-ban fa-stack-2x text-danger" />
        </span>
      )}
    </div>
  )
}

export default class extends Component {
  state = {}
  async componentDidMount() {
    const check = !this.props.nonDiagnostic && (await checks.checkModule(this.props.module))
    this.setState({ check })
  }

  render() {
    const { check } = this.state
    const { module, _id, url, chapters, score, nonDiagnostic, highlights } = this.props
    const { type, instruction, longInstruction, internalName, name, title, toolButton, timeLimit, canGoBack } = module

    let specific
    switch (type) {
      case 'augmented_reality':
        specific = <AugmentedReality {...this.props} />
        break
      case 'differences':
        specific = <Differences {...this.props} />
        break
      case 'gallery':
        specific = <Gallery {...this.props} />
        break
      case 'score_conversation':
      case 'conversation':
        specific = <Conversation {...this.props} />
        break
      case 'compass':
        specific = <Compass {...this.props} />
        break

      case 'diaporama':
      case 'score_diaporama':
      case 'picture_slider':
        specific = <Diaporama {...this.props} />
        break
      case 'dragdrop':
        specific = <DragDrop {...this.props} />
        break
      case 'image_mcq':
        specific = <ImageMcq {...this.props} />
        break
      case 'level':
        specific = <Level {...this.props} />
        break
      case 'markdown':
        specific = <Markdown {...this.props} />
        break
      case 'mcq':
        specific = <MCQ {...this.props} />
        break
      case 'photosphere':
      case 'differences_photosphere':
        specific = <Photosphere {...this.props} />
        break
      case 'puzzle':
        specific = <Puzzle {...this.props} />
        break
      case 'quizz':
        specific = <Quizz {...this.props} />
        break
      case 'qrcode_hunt':
        specific = <QRCodeHunt {...this.props} />
        break
      case 'rating':
        specific = <Rating {...this.props} />
        break
      case 'rating_store':
        specific = <RatingStore {...this.props} />
        break
      case 'choice':
        specific = <Choice {...this.props} />
        break

      case 'scratch':
        specific = <Scratch {...this.props} />
        break
      case 'selfie':
        specific = <Selfie {...this.props} />
        break
      case 'session':
        specific = <Session {...this.props} />
        break
      case 'shake_contest':
        specific = <ShakeContext {...this.props} />
        break
      case 'slasher':
        specific = <Slasher {...this.props} />
        break
      case 'video':
        specific = <Video {...this.props} />
        break
      default:
        specific = <pre>{/* JSON.stringify(module, null, 2) */}Non géré</pre>
    }

    return (
      <article className={'module ' + type} id={_id}>
        <h3>
          <BookText
            text={name}
            collection="modules"
            id={_id}
            path="name"
            highlights={highlights}
            style={{ display: 'inline-block' }}
          />
          {!name && (
            <BookText
              text={title}
              collection="modules"
              id={_id}
              path="title"
              highlights={highlights}
              style={{ display: 'inline-block' }}
            />
          )}
          {internalName && (
            <BookText
              text={internalName}
              collection="modules"
              id={_id}
              path="internalName"
              highlights={highlights}
              className="internal"
              style={{ display: 'inline-block' }}
            />
          )}
          <Link to={url}>
            <i className="fa fa-edit" />
          </Link>
        </h3>
        <section>
          <aside className="common">
            <small>Type : {moduleLabel[module.type] || module.type}</small>
            <br />
            {instruction && (
              <BookText text={instruction} collection="modules" id={_id} path="instruction" highlights={highlights} />
            )}
            {longInstruction && (
              <BookText text={longInstruction} collection="modules" id={_id} path="longInstruction" highlights={highlights} />
            )}
            {
              <BookResource
                id={module.background}
                style={{ maxWidth: '100%', display: 'inline-block' }}
                path="background"
                highlights={highlights}
              />
            }
            {<BookResource id={module.music} path="music" highlights={highlights} />}
            <div className="icons-container">
              <span className="fa-stack fa-lg" title={`Retour en arrière ${canGoBack ? 'autorisé' : 'interdit'}`}>
                <i className="fa fa-backward fa-stack-1x" />
                {!canGoBack && <i className="fa fa-ban fa-stack-2x text-danger" />}
              </span>

              {timeLimit > 0 && (
                <span>
                  <i className="fa fa-2x fa-clock-o" loop /> {timeLimit} secondes
                </span>
              )}
              {score > 0 && (
                <span>
                  <i className="fa fa-2x fa-trophy" /> {score} pts
                </span>
              )}
            </div>
            {toolButton && toolButton.action && (
              <>
                <BookResource id={toolButton.icon} path="toolButton°|°icon" highlights={highlights} />
                <BookAction action={toolButton.action} chapters={chapters} />
              </>
            )}
          </aside>
          <div>{specific}</div>
          {!nonDiagnostic && <Diagnostic checks={check} />}
        </section>
      </article>
    )
  }
}
