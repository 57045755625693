import React, { useEffect, useState } from 'react'

import { Page } from '..'
import Loading from '../../components/loading'
import { NavInExperience } from '../../components/nav'
import { resources as resourcesApi } from '../../lib/api'

export const ExperienceResources = ({ match }) => {
  const [resources, setResources] = useState()

  useEffect(() => {
    resourcesApi.getFrom('experiences', match.params.experienceUid).then(res => {
      setResources(res)
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Page>
      <NavInExperience />
      <div style={{ display: 'flex', flexDirection: 'column', padding: 5 }}>
        {!resources ? (
          <Loading />
        ) : (
          <div>
            <p>Page en maintenance...</p>
          </div>
        )}
      </div>
    </Page>
  )
}
